export interface UserScopeConfig {
  canLoginToMainOrganization: boolean
  isApplicationManager: boolean
  isAssignable: boolean
  canAssign: boolean
  hasBasicViewScopes: boolean
  hasBasicEditScopes: boolean
  hasAdvancedViewScopes: boolean
  hasAdvancedEditScopes: boolean
  hasBatchScopes: boolean
  hasStatusScopes: boolean
}

/**
 * Function is temporary and will be removed once this logic is put in the backend.
 *
 * This function sets the initial scopes for a user based on the user's role, organization, and selected scope configuration.
 */
export const setInitialUserScopes = async (user: Pick<User, "_id" | "organization" | "userRole">, userScopeConfig: UserScopeConfig) => {
  const authStore = useAuthStore()
  const organizationStore = useOrganizationStore()

  const scopes: Array<AuthScope> = []
  const categories: Array<string> = []

  if (userScopeConfig.canLoginToMainOrganization) {
    scopes.push(AuthScope.CAN_LOGIN)
  }
  if (userScopeConfig.isApplicationManager) {
    scopes.push(AuthScope.ADMIN)
  }
  if (userScopeConfig.isAssignable) {
    scopes.push(AuthScope.CAN_BE_ASSIGNED)
  }
  if (userScopeConfig.canAssign) {
    scopes.push(AuthScope.CAN_ASSIGN_USERS)
  }
  if (userScopeConfig.hasBasicViewScopes) {
    scopes.push(...basicViewScopes)

    // Add all categories from the user's main organization
    const mainOrganizationCategories = await organizationStore.getOrganizationAssetCategories(user.organization)
    categories.push(...mainOrganizationCategories)
  }
  if (userScopeConfig.hasBasicEditScopes) {
    scopes.push(...basicEditScopes)
  }
  if (userScopeConfig.hasAdvancedViewScopes) {
    scopes.push(...advancedViewScopes)
  }
  if (userScopeConfig.hasAdvancedEditScopes) {
    scopes.push(...advancedEditScopes)
  }
  if (userScopeConfig.hasBatchScopes) {
    scopes.push(...batchScopes)
  }
  if (userScopeConfig.hasStatusScopes) {
    scopes.push(...statusScopes)
  }

  // when a user is coupled to an organization which has suppliers. It needs to receive scopes that are linked to those suppliers.
  // const selectedOrganization = organizations.value.find((o) => o._id === newUser.organization)
  const organization = await organizationStore.getOrganization(user.organization)

  if (!organization) {
    throw new Error("Organization not found")
  }
  if (organization.type === OrganizationType.SUPPLIER && user.userRole) {
    // get all organizations that this supplier is linked to
    const supplierRoleScope =
      user.userRole === UserRole.SUPPLIER_MECHANIC
        ? AuthScope.MECHANIC
        : user.userRole === UserRole.SUPPLIER_PLANNER
          ? AuthScope.PLANNER
          : user.userRole === UserRole.SUPPLIER_MANAGER
            ? AuthScope.MAINTAINER
            : null

    if (supplierRoleScope) {
      scopes.push(supplierRoleScope)
    }

    const supplierClients = await organizationStore.getAllSupplierClients(organization._id)
    supplierClients.forEach((client) => {
      const template = client.supplierScopeSelection

      if (!template) {
        return
      }

      const generalScopes = [...((template.hasAccess && [AuthScope.CAN_LOGIN]) || []), ...template.ticketVisibility, ...template.overviews]

      const roleScopes = {
        SUPPLIER_MECHANIC: template.mechanic,
        SUPPLIER_PLANNER: template.planner,
        SUPPLIER_MANAGER: template.manager,
      }

      categories.push(...template.categories)
      // set the scopes for the user for the client
      // awaiting this may cause issues with the category scopes
      authStore.setScopes(user._id, client.id, [...generalScopes, ...roleScopes[user.userRole as SupplierRole]])
    })
  }

  await authStore.setScopes(user._id, user.organization, scopes)
  await authStore.setCategoryScopes(user._id, categories)
}
